import React, { Component } from "react";
import { checkCookie, getCookie } from "../Utils/Utils";
import { Navigate } from "react-router-dom";
import { USER_API, STATION_API, TOKEN_API } from "../../constants/Constants";

import { Button } from "react-bootstrap";
import axios from "axios";

require("./Order.scss");

class Order extends Component {
  componentDidMount() {
    const queryParameters = new URLSearchParams(window.location.search);
    const userid = queryParameters.get("userId");
    let url = USER_API + userid;
    axios.get(url).then(response => {
      console.log(response.data);
      this.setState({ user: response.data.userDetails });
    });
    let tokenDetails = this.state.tokenDetails;
    tokenDetails.stationId = getCookie("stationUserId");
    tokenDetails.userId = userid;
    this.setState({ tokenDetails });
  }
  constructor(props) {
    super(props);

    this.state = {
      user: "",
      tokenDetails: {
        stationId: "",
        userId: "",
        type: "ORDER",
        tokens: 0
      }
    };

    this.handleToken = this.handleToken.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleToken(e) {
    let tokenDetails = this.state.tokenDetails;
    tokenDetails.tokens = e.target.value;
    this.setState({ tokenDetails });
  }

  handleFormSubmit(e) {
    e.preventDefault();

    let tokenDetails = this.state.tokenDetails;

    if (tokenDetails.tokens > 0) {
      console.log(tokenDetails);

      axios
        .post(TOKEN_API, tokenDetails, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          console.log(response);
          this.setState({
            tokenDetails: {
              stationId: "",
              userId: "",
              type: "ORDER",
              tokens: 0
            }
          });
        });
    }
  }

  render() {
    if (!checkCookie("stationUserId")) {
      console.log(false);
      return <Navigate to="/authenticate" />;
    }
    const user = this.state.user;
    return (
      <div className="order-container">
        <div className="title">Purchase Order</div>
        <h4>Name : {user.firstName + " " + user.lastName}</h4>
        <h4>Contact Number : {user.contactNumber}</h4>
        <h4>Available Tokens : {user.availableTokens}</h4>
        <h4>Enter tokens: </h4>
        <input
          type="number"
          onChange={this.handleToken}
          value={this.state.tokenDetails.tokens}
        ></input>
        <Button className="button" onClick={this.handleFormSubmit}>
          Submit
        </Button>
      </div>
    );
  }
}

export default Order;
