import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../components/Login/Login";
import Home from "../components/Home/Home";
import Authenticate from "../components/Authenticate/Authenticate";
import Order from "../components/Order/Order";

const AppRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<Login />} path="/" exact={true} />
      <Route element={<Home />} path="/home" />
      <Route element={<Authenticate />} path="/authenticate" />
      <Route element={<Order />} path="/order" />
    </Routes>
  </BrowserRouter>
);

export default AppRouter;
