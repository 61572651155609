import React, { Component } from "react";
import { checkCookie, getCookie } from "../Utils/Utils";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { Container, Form, Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import {
  USER_API,
  TOKEN_API,
  TOKEN_HISTORY_API
} from "../../constants/Constants";
require("./Home.scss");

class Home extends Component {
  componentDidMount() {
    if (checkCookie("stationUserId")) {
      this.setState({ cookie: true });
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      cookie: false,
      search: "",
      userDetails: null,
      tokenHistory: null,
      tokenDetails: {
        stationId: "",
        userId: "",
        type: "PAYMENT",
        tokens: 0
      }
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
  }

  handleSearch(e) {
    this.setState({ search: e.target.value });
  }

  handleSearchSubmit(e) {
    let userId = "USER" + this.state.search;
    let url = USER_API + userId;

    axios.get(TOKEN_HISTORY_API + userId).then(response => {
      console.log(response.data);
      this.setState({ tokenHistory: response.data.tokenHistory });
      this.setState({ totalTokens: response.data.totalTokens });
      this.setState({ paidTokens: response.data.paidTokens });
      this.setState({ unPaidTokens: response.data.unPaidTokens });

      axios.get(url).then(response => {
        this.setState({ userDetails: response.data.userDetails });
        console.log(response.data);
      });
    });
  }

  handlePayment(e) {
    let tokenDetails = this.state.tokenDetails;
    tokenDetails.userId = this.state.userDetails.userId;
    tokenDetails.tokens = this.state.unPaidTokens;
    tokenDetails.stationId = getCookie("stationId");

    console.log(tokenDetails);

    axios
      .post(TOKEN_API, tokenDetails, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        this.setState({
          tokenDetails: {
            stationId: "",
            userId: "",
            type: "PAYMENT",
            tokens: 0
          }
        });
        alert("Payment Successful");
        this.handleSearchSubmit();
      });
  }

  render() {
    const tokenHistory = this.state.tokenHistory;
    const totalTokens = this.state.totalTokens;
    const paidTokens = this.state.paidTokens;
    const unPaidTokens = this.state.unPaidTokens;

    if (!checkCookie("stationUserId")) {
      console.log(false);
      return <Navigate to="/authenticate" />;
    }
    return (
      <Container>
        <h1>Finance Portal</h1>
        <Form className="d-flex">
          <Form.Control
            type="search"
            placeholder="Search"
            className="me-2"
            aria-label="Enter User Id"
            onChange={this.handleSearch}
          />
          <Button variant="outline-success" onClick={this.handleSearchSubmit}>
            Search
          </Button>
        </Form>
        {this.state.userDetails !== null && (
          <div className="token-history-container">
            <h3>User Information</h3>

            <h4>
              Name:{" "}
              {this.state.userDetails.firstName +
                " " +
                this.state.userDetails.lastName}
            </h4>
            <h4>Contact Number: {this.state.userDetails.contactNumber}</h4>
            <h4>Email Address: {this.state.userDetails.emailAddress}</h4>
            <div className="token-history-title">DIGITAL TOKEN HISTORY</div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Station Name </th>
                  <th>Type</th>
                  <th>Tokens</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(tokenHistory).map(key => (
                  <tr key={key}>
                    <td>{parseInt(key) + 1}</td>
                    <td>{tokenHistory[key].stationName}</td>
                    <td>{tokenHistory[key].type}</td>
                    <td>{tokenHistory[key].tokens}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div>Total Tokens purchased: {totalTokens}</div>
            <div>Tokens Paid: {paidTokens}</div>
            <div>Tokens that are not paid: {unPaidTokens}</div>
            <Button onClick={this.handlePayment}>Confirm Payment</Button>
          </div>
        )}
      </Container>
    );
  }
}

export default Home;
