import React, { Component } from "react";
import axios from "axios";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HOSTURL, STATION_AUTH_API } from "../../constants/Constants";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";
require("./Authenticate.scss");

class Authenticate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileNumber: "",
      showModal: false,
      authenticate: false,
      user: null
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleMobile = this.handleMobile.bind(this);
    this.handleVerifyCode = this.handleVerifyCode.bind(this);
  }

  handleMobile(e) {
    this.setState({ mobileNumber: e.target.value });
  }

  isValidPhoneNumber(value) {
    const regexp = /^\d{10}$/;
    return regexp.test(value);
  }

  handleFormSubmit(e) {
    e.preventDefault();

    let mobileNumber = this.state.mobileNumber;

    if (this.isValidPhoneNumber(mobileNumber)) {
      this.setState({ showModal: true });
      let url = HOSTURL + STATION_AUTH_API + mobileNumber;
      console.log(url);
      axios.get(url).then(response => {
        this.setState({ user: response.data });
      });
    }
  }

  handleVerifyCode(e) {
    if (this.state.user.authenticationCode === Number(e.target.value)) {
      //console.log("authentication code match");
      this.setState({ pathname: "/home" });
      Cookies.set("stationUserId", this.state.user.stationUserId, {
        expires: 8
      });
      Cookies.set("stationId", this.state.user.stationId, {
        expires: 8
      });
    }
  }

  render() {
    return (
      <Container className="auth-container">
        <Modal show={this.state.showModal}>
          <Modal.Header>
            <Modal.Title>Verify your details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="verification-modal-text">
              Enter the authentication code sent to: {this.state.mobileNumber}
            </div>
            <Form.Control type="text" onChange={this.handleVerifyCode} />
          </Modal.Body>
          <Modal.Footer>
            <Link
              to={{
                pathname: this.state.pathname,
                state: this.state
              }}
            >
              <Button variant="primary">Confirm</Button>
            </Link>
          </Modal.Footer>
        </Modal>
        <div className="title">Resonate Finance Station</div>
        <div className="input-group">
          <div className="input-fields">
            <Form.Control
              className="number"
              type="text"
              onChange={this.handleMobile}
              placeholder="Enter Your Mobile Number"
              value={this.state.mobileNumber}
            />
            <Button className="button" onClick={this.handleFormSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </Container>
    );
  }
}

export default Authenticate;
