import React, { Component } from "react";
import Home from "../Home/Home";
import Authenticate from "../Authenticate/Authenticate";
import { checkCookie } from "../Utils/Utils";

class Login extends Component {
  componentDidMount() {
    if (checkCookie("stationUserId")) {
      this.setState({ cookie: true });
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      cookie: false
    };
  }

  render() {
    return this.state.cookie ? <Home /> : <Authenticate />;
  }
}

export default Login;
